export default {
  env: 'develop',
  kibanaApi: 'https://api-support-test.xfinity.com/logger/',
  apis: {
    ssm: 'https://api-qa.sc.xfinity.com',
  },
  oauthOptions: {
    idmClientId: 'dss-refund-development',
    clientId: 'mtm-account-change-dev',
  },
};
