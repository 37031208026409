import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Loading } from 'adc-ui-components';

import Landing from './Views/Landing';
import Review from './Views/Review';
import Status from './Views/Status';
import Confirmation from './Views/Confirmation';
import Email from './Views/Email';
import MailingAddress from './Views/MailingAddress';
import InEligible from './Views/InEligible';
import Error from './Views/Error';
import RefundIneligible from '../../components/refund/RefundIneligible';

import {
  SPORTS_REFUND,
  SPORTS_REFUND_REVIEW,
  SPORTS_REFUND_CONFIRMATION,
  SPORTS_REFUND_HISTORY,
  SPORTS_REFUND_EMAIL_EDIT,
  SPORTS_REFUND_MAIL_EDIT,
  SPORTS_REFUND_INELIGIBLE,
  SPORTS_REFUND_ERROR,
} from '../../helpers/routes';
import {
  getCancelDestination,
  getQueryString,
  refundEligibility,
} from '../../helpers/SportsRefundHelpers';
import { getSportsRefund } from './Actions/GetSportsRefund';
import { updateCancelUrl } from './Actions/UpdateCancelURL';

const SportsRefundApp = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { loading, states, error } = useSelector(state => state.sportsRefund);

  const { authorization } = states || {};
  const { qaParams } = props;
  const queryString = getQueryString(qaParams);

  let { role } = states || {};
  role = role ? role.toLowerCase() : '';

  useEffect(() => {
    // check if user is eligible for a refund
    // if eligible redirect to the refund landing page
    // if user not eligible for refund, check
    // if they have a pending request
    // if there is a request pending redirect user refund history page
    (async () => {
      try {
        const refundInfo = await dispatch(getSportsRefund(queryString));
        const {
          payload: { refund = [] },
        } = refundInfo;
        const isEligibleForRefund = refundEligibility(refund);
        if (!isEligibleForRefund) {
          const hasRequestedRefund = refund.some(
            item => item.status === 'requested',
          );
          const redirectTo = hasRequestedRefund
            ? SPORTS_REFUND_HISTORY
            : SPORTS_REFUND_INELIGIBLE;
          history.push(redirectTo);
        }
      } catch (e) {
        history.push(SPORTS_REFUND_ERROR);
      }
    })();
  }, [dispatch, history, queryString]);

  useEffect(() => {
    const title = document.querySelector('h1');
    if (title) {
      title.focus();
    }

    if (!states && location.pathname !== SPORTS_REFUND) {
      history.push(SPORTS_REFUND);
    }
  }, [history, location.pathname, states]);

  useEffect(() => {
    if (location.search) {
      dispatch(updateCancelUrl(getCancelDestination(location)));
    }
  }, [dispatch, location]);

  if (loading) {
    return <Loading />;
  }

  // Primaries & users with Billpay permissions
  // eligible for refunds
  if (
    authorization !== 'LITE' &&
    !error &&
    !role.includes('primary') &&
    !role.includes('billpay') &&
    !role.includes('manager')
  ) {
    return <RefundIneligible />;
  }

  return (
    <Switch>
      <Route exact path={SPORTS_REFUND_INELIGIBLE} component={InEligible} />
      <Route exact path={SPORTS_REFUND_EMAIL_EDIT} component={Email} />
      <Route exact path={SPORTS_REFUND_MAIL_EDIT} component={MailingAddress} />
      <Route exact path={SPORTS_REFUND_CONFIRMATION} component={Confirmation} />
      <Route exact path={SPORTS_REFUND_REVIEW} component={Review} />
      <Route exact path={SPORTS_REFUND_HISTORY} component={Status} />
      <Route exact path={SPORTS_REFUND_ERROR} component={Error} />
      <Route component={Landing} />
    </Switch>
  );
};

export default SportsRefundApp;
