import React from 'react';
import { useSelector } from 'react-redux';
import RefundIneligible from '../../../components/refund/RefundIneligible';
import ActiveUserIneligible from '../../../components/refund/ActiveUserIneligible';
import getConfig from '../../../config';

const InEligible = () => {
  const { states: { accountStatus = '' } = {} } = useSelector(
    state => state.sportsRefund,
  );

  const { env } = getConfig();

  const isEnvEligible = env !== 'pre-prod' || env !== 'production';

  return accountStatus === 'CURRENT' && isEnvEligible ? (
    <ActiveUserIneligible />
  ) : (
    <RefundIneligible />
  );
};

export default InEligible;
