import {
  GET_SPORTS_REFUND,
  GET_SPORTS_REFUND_FAILURE,
  GET_SPORTS_REFUND_SUCCESS,
} from '../Actions/GetSportsRefund';
import {
  ISSUE_SPORTS_REFUND,
  ISSUE_SPORTS_REFUND_FAILURE,
  ISSUE_SPORTS_REFUND_SUCCESS,
} from '../Actions/IssueSportsRefund';
import { UPDATE_CANCEL_URL } from '../Actions/UpdateCancelURL';
import { UPDATE_EMAIL_ADDRESS } from '../Actions/UpdateEmailAddress';
import { UPDATE_PREFERRED_BILLING } from '../Actions/UpdateBillingAddress';

const INITIAL_STATE = {
  loading: true,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_SPORTS_REFUND:
      return {
        ...state,
        loading: true,
      };
    case GET_SPORTS_REFUND_SUCCESS:
      return {
        ...state,
        loading: false,
        states: action.payload,
      };
    case GET_SPORTS_REFUND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_CANCEL_URL:
      return {
        ...state,
        cancelUrl: action.payload,
      };
    case UPDATE_EMAIL_ADDRESS:
      return {
        ...state,
        states: {
          ...state.states,
          updatedEmail: action.payload,
        },
      };
    case UPDATE_PREFERRED_BILLING:
      return {
        ...state,
        states: {
          ...state.states,
          updatedMailingAddress: action.payload,
        },
      };
    case ISSUE_SPORTS_REFUND:
      return {
        ...state,
        submitLoading: true,
      };
    case ISSUE_SPORTS_REFUND_SUCCESS:
      return {
        ...state,
        submitLoading: false,
        confirmationData: action.payload,
      };
    case ISSUE_SPORTS_REFUND_FAILURE:
      return {
        ...state,
        submitLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
