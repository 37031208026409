import React, { useState } from 'react';
import Document from './svgs/Document';

const DocumentsReview = ({ docsToUpload }) => {
  const maxDocsToShow = 3;
  // Hash of all the documents in store
  const docsToUploadHash = Object.keys(docsToUpload || {});
  // Setting the hash of documents to be viewed in an array & changing it on see more / see less events.
  const [viewDocsHash, setViewDocsHash] = useState(
    docsToUploadHash.slice(0, maxDocsToShow),
  );
  const hasMoreDocsToShow = viewDocsHash.length > maxDocsToShow;
  const docsToUploadHashCount = docsToUploadHash.length;
  const moreDocsAvailableCount = docsToUploadHashCount - maxDocsToShow;
  return (
    <div data-notranslate="true" className="document-review body4">
      {
        // Iterating over the hash array and using the hash as a key to get the particular doc information from the docsToUpload object.
      }
      {viewDocsHash.map(hash => {
        return (
          <span
            data-notranslate="true"
            className="document-review__value body4 fw500"
            key={docsToUpload[hash].documentId}
            title={docsToUpload[hash].fileName}
          >
            <Document />
            {docsToUpload[hash].fileName}
          </span>
        );
      })}
      {docsToUploadHashCount > maxDocsToShow && (
        <button
          type="button"
          className="button button--text"
          onClick={() =>
            hasMoreDocsToShow
              ? setViewDocsHash(docsToUploadHash.slice(0, maxDocsToShow))
              : setViewDocsHash(docsToUploadHash)
          }
        >
          {hasMoreDocsToShow
            ? `- see less`
            : `+ see more (${moreDocsAvailableCount})`}
        </button>
      )}
    </div>
  );
};

export default DocumentsReview;
